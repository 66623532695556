.createTeam_con {
    width: 700px;

    & label {
        color: var(--Secondary-Main, #101828);
        font-size: 20px;
        font-family: DM Sans-Medium;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.24px;
        margin-bottom: 8px;
        color: #101828;
    }

    .heading {
        color: var(--Secondary-Main, #101828);
        font-size: 24px;
        font-family: DM Sans-Medium;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        /* 108.333% */
    }

    .team_leader {
        color: var(--Dark-Base-Blue, #002356);
        font-family: DM Sans-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    & sup {
        color: red;
    }

    .or_con {
        display: flex;
        align-items: center;
        text-wrap: nowrap;

        .line {
            border: 0.5px solid #818796;
            height: 0px;
        }
    }
    
}

.Label{
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #101828;
    
}
.heading1{
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #101828;
}
.p-dialog-content {
    padding: 0px;
    border-radius: 16px;
}



.deleteParent{
    .deletePopup {
        width: 560px;
        height: fit-content;
        padding: 40px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }
    
    .popupText {
        display: flex;
        flex-direction: column;
        height: 50%;
    }
    
    .popupText h1 {
        margin: 0 !important;
        color: var(--Secondary-Main, #101828);
        font-family: Georgia;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
    
    .popupText p {
        margin: 24px 0 0 0 !important;
        color: var(--Text, #040F2D);
        font-family: "DM Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .popupButtons {
        display: flex;
        flex-direction: row-reverse;
        height: 50%;
        padding-top: 48Px;
    }
    
    .deleteButton,
    .cancelButton {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
    }
    
    .cancelButton {
        width: 98px;
        height: 40px;
        margin-right: 24px;
        border: 1px solid #101828;
        columns: #101828;
        background-color: white;
    }
    
    .deleteButton {
        width: 123px;
        height: 40px;
        border: none;
        color: white;
        background-color: #D00000;
        display: flex;
        justify-content: space-between;
    }
}
.icon1,
.icon2,
.icon3 {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 16px;
}

.icon1 {
    color: #1877F2;
}

.icon2 {
    color: #FFB800;
}

.icon3 {
    color: #D00000;
}
 .BlockButton {
    /* width: 123px; */
    /* height: 40px; */
    border: none;
    color: white;
    border-radius: 
    8px;
    background-color: #D00000;
    padding: 8px 24px;
    /* display: flex; */
    /* justify-content: space-between; */
}
.AddButton{
    background-color: #002356;
    padding: 8px 24px;
    border: 10px !important;
    color: white;
    font-size: 16px;
    font-weight: 400 !important;
    border: none;
    color: white;
}
.Profillogo{
    width: 32px;
    height: 32px;
}
.passwordInput_con {
    position: relative;

    .eye {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
    }
}

.input_con {
    & input {
        height: 40px;
        padding: 8px 12px 8px 16px;
        border-radius: 8px;
        border: 1px solid var(--Secondary-40, #D0D5DD);
        background: #FFF;
        width: 100%;
        letter-spacing: 0.192px;

        &:focus {
            outline: 1px solid var(--Secondary-40, #D0D5DD);
            box-sizing: border-box;
        }
    }
}

.select_con{
    .select_field{
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;

    }
}


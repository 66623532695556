.sidebar_con {
    height: 100vh;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 232px 1fr;

    .left_con {
        height: 100%;
        border-radius: 0px 25px 25px 0px;
        background: var(--Dark-Base-Blue, #002356);

        .logo_main_con {
            margin: 44px 0px 60px 0px;
            display: flex;
            justify-content: center;
        }

        .navlink_con {
            padding: 0px 28px;

            .navlink {
                display: flex;
                align-items: center;
                column-gap: 20px;
                color: var(--PT-40, #9B9FAB);
                font-size: 20px;
                font-weight: 500;
                text-transform: capitalize;
                margin-top: 10px;

            }
        }
    }

    .right_con {
        box-sizing: border-box;
        .top_bar_con {
            height: 100px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 28px;

            .nav_title {
                color: #000;
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: capitalize;
            }

            .language_select {
                border-radius: 12px;
                background: rgba(15, 155, 90, 0.15);
                margin-right: 40px;
            }

            .icon_con {
                display: flex;
                align-items: center;
                font-size: 30px;
                column-gap: 20px;
            }
        }
        .children_con{
            padding: 0px 24px 24px 24px;
            .child_body{
                background-color: #F3F3FA;
                overflow: auto;
                height: calc(100vh - 124px);
                box-sizing: border-box;
                border-radius: 25px  ;
                padding: 32px;
                
            }

        }
    }

}
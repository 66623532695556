/* {{{{{{{{{{{{{{============= css for logo component =============}}}}}}}}}}}}}}}}}]]]]] */
.logo_con {
    width: fit-content;

    .logo_text {
        color: var(--White, #FFF);
        font-family: Georgia;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin-left: 16px;
    }
}

/* {{{{{{{{{{============  login auth template css  ===============}}}}}}}}}} */
.login_auth_template_main {
    height: 100vh;
    overflow: auto;
    display: flex;
    background: var(--Dark-Base-Blue, #002356);

    .left {
        width: 60%;
        max-height: 100vh;
        overflow: auto;
        padding: 20px 0px;

        background-image: url("../../assests/images/loginLeft.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0px 25px 25px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::-webkit-scrollbar {
            width: 0px;
        }


        .left_text {
            width: 70%;
            color: var(--Text, #040F2D);
            text-align: center;
            font-family: Georgia;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 156.123%;
        }
    }


    .right {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;

        .right_content {
            width: 85%;

        }

    }

    @media (max-width:900px) {
        .left {
            width: 50%;

            .left_text {
                font-size: 20px;
            }
        }

        .right {
            width: 50%;

            .right_content {
                width: 95%;

            }
        }
    }

    @media (max-width:400px) {
        .left {
            width: 0%;

        }

        .right {
            width: 100%;

        }
    }

}


/* {{{{{{{{{{{{{{{{===========  toast css   ============}}}}}}}}}}}}}}}} */
.customToast_main_con {
    .error {
        color: #EF4444;
        background-color: #ef444449;
    }

    .success {
        background-color: #20be5a4f;
        color: #20BE5A;
    }

    .customToast_con {
        margin-top: 10px;
        height: 80px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        .message_con {
            display: flex;
            height: 100%;
            align-items: center;
            column-gap: 10px;

            .error_item {
                height: 100%;
                background-color: #EF4444;
                width: 12px;
            }

            .success_item {
                height: 100%;
                background-color: green;
                width: 12px;
            }

            .message {
                flex: 1;
            }
        }
    }
}

/* {{{{{{{{{{==========  tablecontainer css  ============}}}}}}}}}} */
.table_container_con {
    & table {
        width: 100%;
    }

    & th {
        color: var(--Text, #040F2D);
        font-family: DM Sans-Bold;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        border-bottom: 0.5px solid #040F2D;
        border-bottom: 1px solid #040F2D !important;
        text-align: left;
        padding: 8px 0px;
        background-color: white;
    }

    & td {
        color: var(--Text, #040F2D);
        font-family: DM Sans-Medium;
        font-size: 20px;
        font-style: normal;
        line-height: normal;
        padding: 16px 0px;
        border-bottom: 0.1px solid #c2cdec;
    }
}

/* {{{{{{========= pagination css  ============}}}}}} */

.pagination_con {
    border-radius: 0px 0px 25px 25px;
    overflow: hidden;
}

/* {{{{{{========= NoDataPage css  ============}}}}}} */
.noData_page_con {
    .defaultParent {
        background-color: none;
        width: 100%;
        /* height: 100vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .defaultImgBox {
        width: 40%;
        height: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .defaultImgBox img {
        margin-top: 10%;
        width: 100%;
        height: 90%;
    }

    .defaultDataBox {
        height: 40%;
        margin-top: 2%;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .defaultDataBox h3 {
        font-family: Georgia;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        margin: 16px 0 0 0;
    }

    .defaultDataBox p {
        font-family: "DM Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 16px 0 32px 0;
        text-align: center;
    }

    @media screen and (max-width: 500px) {
        .defaultImgBox {
            width: 300px;
            height: 50%;
        }
    }
}

/* --------------{{{{{NoData_Table/ user_management_con css}}}}}-------------- */
.user_management_con {
    .table_container {
        background-color: white;
        padding: 32px 24px;
        border-radius: 25px 25px 25px 25px;
        height: 100%;
        overflow: auto;
    }

    th,
    td {
        width: 40%;
    }


    .firstColumn{
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
    }
    .firstColumn p{
        margin: 0 !important;
    }
    .firstColumn img{
        width: 32px;
        height: 32px;
    }

    @media screen and (max-width: 900px) {

        th,
        td {
            width: 33.33%;
        }
    }

    .icon1,
    .icon2,
    .icon3 {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 16px;
    }

    .icon1 {
        color: #1877F2;
    }

    .icon2 {
        color: #FFB800;
    }

    .icon3 {
        color: #D00000;
    }
}

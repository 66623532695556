@font-face {
  font-family: 'DM Sans-Bold';
  src: url('assests/fonts/DMSans-Bold.ttf');
}

@font-face {
  font-family: 'DM Sans-Medium';
  src: url('assests/fonts/DMSans-Medium.ttf');
}

@font-face {
  font-family: 'DM Sans-Regular';
  src: url('assests/fonts/DMSans-Regular.ttf');
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.align_center {
  align-items: center;
}

.justify_center {
  justify-content: center;
}

.end {
  justify-content: flex-end;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.pointer {
  cursor: pointer;
}

.no_underline {
  text-decoration: none;
}


/* [[[[[{{{{{{{{{{{===========  input css  ==========}}}}}}}}}}}}}}]]]] */

.custom_input {
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  background: #FFF;
  color: var(--PT-80, #363F57);
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.custom_input:focus {
  outline: 2px solid var(--CTA, #2AC37D);
  box-sizing: border-box;
}


.invalid_input {
  border: 2px solid red !important;
}
.error{
  color: red !important;
}

.active div {
  color: var(--PT-5, #F2F3F4);
}

/* +++++++++++++++++++++primereact classess css start ++++++++++++++++++++ */
.p-dialog-content {
  padding: 40px;
  border-radius: 6px;
}

.p-dialog-header {
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}
/* +++++++++++++++++++++primereact classess css end ++++++++++++++++++++ */

.btn_secondary {
  border-radius: 8px;
  border: 1px solid var(--Secondary-Main, #101828);
  color: var(--Secondary-Main, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.btn_primary {
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: var(--White, #FFF);
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    background: var(--Dark-Base-Blue, #002356);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
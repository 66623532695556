/* --------------{{{{{NoData_Table Dilog Box css}}}}}-------------- */
.p-dialog-header {
    display: none;
}

.editUserParent {
    width: 100%;

    p {
        margin: 0 !important;
    }
    padding: 40px;

    .editUserCard {
        margin: auto;
        align-items: center;
        width: 100%;
        background-color: white;
    }

    .editUserCard form button {
        color: var(--White, #FFF);
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        border: none;
        border-radius: 8px;
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .editUserUserDetails {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    .editUserHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .editUserHeaderLeft {
        align-items: center;
    }

    .editUserHeaderLeft h1 {
        color: var(--Secondary-Main, #101828);
        font-family: "DM Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
    }

    .editUserHeaderRight {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .editUserHeaderRight button {
        background: var(--dec, #D00000);
        height: fit-content;
    }

    .editUserHeaderDataForm {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .editUserHeaderDataForm .userName,
    .editUserHeaderDataForm .userEmail {
        display: flex;
        flex-direction: column;
    }

    .editUserHeaderDataForm .userName label,
    .editUserHeaderDataForm .userEmail label {
        display: flex;
        flex-direction: row;
    }

    .editUserHeaderDataForm .userName input,
    .editUserHeaderDataForm .userEmail input {
        display: flex;
        height: 40px;
        padding: 8px 12px 8px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Secondary-40, #D0D5DD);
        background: #FFF;
        color: #000;
        font-family: "DM Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 125% */
        letter-spacing: 0.192px;
    }

    .inputLabel {
        color: var(--Secondary-Main, #101828);
        font-family: "DM Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 23.4px;
        /* 117% */
        letter-spacing: 0.24px;
    }

    .medatoryLabel {
        color: var(--dec, #D00000);
        font-family: "DM Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 23.4px;
        letter-spacing: 0.24px;
    }

    .editUserBottom {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: end;
    }

    .editUserBottom .cancel {
        border-radius: 8px;
        border: 1px solid var(--Secondary-Main, #101828);
        color: #101828;

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .editUserBottom .saveChanges {
        border-radius: 8px;
        background: var(--Dark-Base-Blue, #002356);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
}
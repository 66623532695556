.team_management_con {
    /* border: 2px solid red; */

    .table_container {
        background-color: white;
        padding: 32px 24px;
        border-radius: 25px 25px 0px 0px;
        height: calc(100vh - 310px);
        overflow: auto;

    }

    .icon1,
    .icon2,
    .icon3 {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 16px;
    }

    .icon1 {
        color: #1877F2;
    }

    .icon2 {
        color: #FFB800;
    }

    .icon3 {
        color: #D00000;
    }
}
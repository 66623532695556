.team_management_con {
  /* border: 2px solid red; */

  .table_container {
    background-color: white;
    padding: 28px 24px;
    border-radius: 25px 25px 0px 0px;
    height: calc(100vh - 268px);
    overflow: auto;
  }

  .icon1,
  .icon2,
  .icon3 {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 16px;
  }

  .icon1 {
    color: #1877f2;
  }

  .icon2 {
    color: #ffb800;
  }

  .icon3 {
    color: #d00000;
  }

  .main_heading {
    font-size: 24px;
    font-weight: 700;
    color: black;
  }
  .viewPost_btn {
    border-radius: 12px;
    background: rgba(204, 211, 221, 1);

    font-size: 16px;
    text-align: center;
    padding: 4px 20px !important;
    line-height: 20px;
  }

  .custom_font_size {
    font-size: 16px;
  }
}

.p-dialog-content {
    padding: 0px;
    border-radius: 16px;
}



.deleteParent{
    .deletePopup {
        width: 560px;
        height: fit-content;
        padding: 40px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
    }
    
    .popupText {
        display: flex;
        flex-direction: column;
        height: 50%;
    }
    
    .popupText h1 {
        margin: 0 !important;
        color: var(--Secondary-Main, #101828);
        font-family: Georgia;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }
    
    .popupText p {
        margin: 24px 0 0 0 !important;
        color: var(--Text, #040F2D);
        font-family: "DM Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .popupButtons {
        display: flex;
        flex-direction: row-reverse;
        height: 50%;
        padding-top: 48Px;
    }
    
    .deleteButton,
    .cancelButton {
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;
    }
    
    .cancelButton {
        width: 98px;
        height: 40px;
        margin-right: 24px;
        border: 1px solid #101828;
        columns: #101828;
        background-color: white;
    }
    
    .deleteButton {
        width: 123px;
        height: 40px;
        border: none;
        color: white;
        background-color: #D00000;
        display: flex;
        justify-content: space-between;
    }
}
.auth_main {
    max-height: 100vh;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0px;
    }

    padding: 20px 0px;

    & label {
        color: var(--White, #FFF);
        font-size: 16px;
        font-weight: 500;
    }



    .login_btn {
        border-radius: 12px;
        background: var(--CTA, #2AC37D);
        padding: 12px;
        border: none;
        width: 100%;
        color: var(--white, #FFF);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .verification_text {
        color: var(--PT-5, #F2F3F4);
        font-family: DM Sans;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 65px;
        padding: 10px 20px;
        border-left: 8px solid var(--CTA, #2AC37D);
    }

    .or_con {
        display: flex;
        color: white;
        align-items: center;

        .line {
            border: 0.5px solid white;
            height: 0px;
        }
    }
}